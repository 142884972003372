<template>
  <div v-if="isLoading">
    <!-- Loading... -->
  </div>
  <div
    v-else-if="pagination.count / pagination.page_size > 1"
    class="flex gap-1.5 justify-center"
  >
    <button
      @click="openPage(pagination.page - 1)"
      class="px-2.5 py-1.5 rounded-sm items-center flex"
      :class="{ 'opacity-50': !pagination.previous }"
      :disabled="!pagination.previous"
    >
      <img
        class="leading-4 transform-gpu rotate-90"
        src="@/assets/images/icons/chevron_black.svg"
        alt=""
      />
      <span class="ml-1.5 font-semibold text-sm leading-4">Prev</span>
    </button>

    <button
      v-for="page in pages"
      :key="'button-' + page.title"
      :disabled="page.disabled"
      @click="openPage(page.title)"
      class="px-2.5 py-1.5 rounded-sm font-semibold text-sm leading-4"
      :class="{ 'bg-blue text-white': pagination.page == page.title }"
    >
      {{ page.title }}
    </button>

    <button
      @click="openPage(+pagination.page + 1)"
      class="px-2.5 py-1.5 rounded-sm items-center flex"
      :class="{ 'opacity-50': !pagination.next }"
      :disabled="!pagination.next"
    >
      <span class="font-semibold text-sm leading-4 opac">Next</span>
      <img
        class="ml-1.5 leading-4 transform-gpu -rotate-90"
        src="@/assets/images/icons/chevron_black.svg"
        alt=""
      />
    </button>
  </div>
</template>

<script>
// 1
// 1 2
// 1 2 3
// 1 2 3 4
// 1 2 3 4 5
// 1 ... 4 [5] 6 ... 9
// [1] 2 3 ... 9
// 1 2 3 [4] 5 ... 9
// 1 ... 5 [6] 7 8 9
// 1 ... 7 8 [9]

import { computed, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

export default {
  props: ["pagination"],
  setup(props) {
    const route = useRoute();
    const router = useRouter();

    const isLoading = computed(() => props.pagination === null);

    const pages = ref([]);

    function getPagination() {
      let current = props.pagination.page || 1,
        last = Math.ceil(props.pagination.count / props.pagination.page_size),
        delta = 2,
        left = current - delta,
        right = current + delta + 1,
        range = [],
        rangeWithDots = [],
        l;

      for (let i = 1; i <= last; i++) {
        if (i == 1 || i == last || (i >= left && i < right)) {
          range.push(i);
        }
      }

      for (let i of range) {
        if (l) {
          if (i - l === 2) {
            rangeWithDots.push({
              title: l + 1,
              disabled: false,
            });
          } else if (i - l !== 1) {
            rangeWithDots.push({
              title: "...",
              disabled: true,
            });
          }
        }
        rangeWithDots.push({
          title: i,
          disabled: false,
        });
        l = i;
      }

      pages.value = rangeWithDots;
    }

    const openPage = function (page) {
      router.push({ path: route.path, query: { ...route.query, page: page } });
    };

    watch(
      () => props.pagination,
      () => {
        if (props.pagination !== null) getPagination();
      },
      { immediate: true }
    );

    return {
      isLoading,
      pages,
      openPage,
    };
  },
};
</script>
