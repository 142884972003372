<template>
    <ui-modal @close="close" width="457">
    <div class="flex flex-col items-center">
      <img
        v-if="success"
        class="mt-16"
        src="@/assets/images/icons/check_blue.svg"
        alt="check"
      />
      <img
        v-if="error"
        class="mt-16 w-11"
        src="@/assets/images/icons/error_circle.svg"
        alt="error_circle"
      />
      <h3 class="text-4.5xl font-semibold mt-5 leading-10">
        {{ title }}
      </h3>
      <p class="mt-3 font-medium leading-5 text-center px-10" :class="{ 'text-red-darker': error }">
        <slot>
          Something went wrong 
        </slot>
      </p>
      <div class="flex mt-10 mb-14">
        <ui-button
          v-if="success"
          width="88"
          oval
          :button-type="ButtonType.Blue"
          @click="close"
        >
          Ok
        </ui-button>
        <ui-button
          v-if="error"
          oval
          @click="close"
          width="88"
        >
          Ok
        </ui-button>
      </div>
    </div>
  </ui-modal>
</template>
<script>
import UiModal from "@/components/ui/UiModal.vue";
import UiButton from "@/components/ui/UiButton.vue";
import { ButtonType } from "@/enums/button";

export default{
    components:{
        UiModal,
        UiButton
    },
    props:{
        title: String,
        success: Boolean,
        error: Boolean,
    },
    setup(props, { emit }){
        const close = () => emit("close");

        return{
            close,
            ButtonType,
        }
    }
}
</script>