import { SupplierRatingEnum } from '@/core/enums/suppliers';
import { SelectOptionModel } from '@/core/models/select-option';
import { normalizeUpperCase } from '@/utils/normalizeUpperCase';
import { FilterItemModel } from '@/core/models/filter-item';
import { AssessmentStatusEnum } from '../enums/assessments';
import { InfoNumberEnum } from '../enums/info_number';
import { COLORS } from './colors';

export const SUPPLIER_RATING_LIST = [
  SupplierRatingEnum.HIGH_RISK,
  SupplierRatingEnum.MODERATE_RISK,
  SupplierRatingEnum.TOLERABLE,
  SupplierRatingEnum.NOT_RATED,
];

export const SUPPLIER_RATING_LIST_OPTIONS: Array<SelectOptionModel> =
  SUPPLIER_RATING_LIST.map(
    (item) => new SelectOptionModel(normalizeUpperCase(item), item)
  );

export const SUPPLIER_RATING_FILTERS: Array<FilterItemModel> =
  SUPPLIER_RATING_LIST.map(
    (item) => new FilterItemModel(item, normalizeUpperCase(item))
  );

export const SUPPLIERS_STATUS_VIEW = {
  [AssessmentStatusEnum.COMPLETED]: {
    value: 'Completed',
    color: COLORS.GRAY,
  },
  [AssessmentStatusEnum.ON_EVALUATOR]: {
    value: 'Evaluation',
    color: COLORS.BLUE_HOVER,
  },
  [AssessmentStatusEnum.ON_SUPPLIER]: {
    value: 'In progress',
    color: COLORS.YELLOW,
  },
  [AssessmentStatusEnum.OVERDUE]: {
    value: 'Overdue',
    color: COLORS.GRAY_08,
  },
  [AssessmentStatusEnum.DRAFT]: {
    value: 'Draft',
    color: COLORS.BLUE,
  },
};

export const SUPPLIER_ACTUAL_RATING_LIST: Array<FilterItemModel> = [
  new FilterItemModel('0', 'No rating'),
  new FilterItemModel(InfoNumberEnum.LOW, 'Low'),
  new FilterItemModel(InfoNumberEnum.MEDIUM, 'Medium'),
  new FilterItemModel(InfoNumberEnum.HIGH, 'High'),
];
