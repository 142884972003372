import { SelectOptionModel } from './../models/select-option';
import { FilterItemModel } from './../models/filter-item';
import { InfoNumberEnum } from '../enums/info_number';

export const INFO_NUMBER_LABELS = {
  [InfoNumberEnum.HIGH]: "High",
  [InfoNumberEnum.MEDIUM]: "Medium",
  [InfoNumberEnum.LOW]: "Low",
}

export const INFO_NUMBER_FILTERS: Array<FilterItemModel> = [
  new FilterItemModel(InfoNumberEnum.LOW, INFO_NUMBER_LABELS[InfoNumberEnum.LOW]),
  new FilterItemModel(InfoNumberEnum.MEDIUM, INFO_NUMBER_LABELS[InfoNumberEnum.MEDIUM]),
  new FilterItemModel(InfoNumberEnum.HIGH, INFO_NUMBER_LABELS[InfoNumberEnum.HIGH])
];

export const INFO_NUMBER_SELECT_OPTIONS: Array<SelectOptionModel> = [
  new SelectOptionModel(INFO_NUMBER_LABELS[InfoNumberEnum.LOW], InfoNumberEnum.LOW),
  new SelectOptionModel(INFO_NUMBER_LABELS[InfoNumberEnum.MEDIUM], InfoNumberEnum.MEDIUM),
  new SelectOptionModel(INFO_NUMBER_LABELS[InfoNumberEnum.HIGH], InfoNumberEnum.HIGH),
]